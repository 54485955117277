<template>
  <base-widget title="Registered">
    <v-progress-circular
      :value="value"
      color="#F57F17"
      rotate="270"
      size="128"
      width="24"
    />

    <div class="pa-3 headline font-weight-light">
      {{ totalDevices }}
    </div>
  </base-widget>
</template>

<script>
  export default {
    name: 'DevicesRegistered',

    data: () => ({
      value: 100,
    }),
    computed: {
      totalDevices(){
        return this.$store.state.totalDevices
      }
    }
  }
</script>
